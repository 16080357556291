// BasketContainer.js

import React, {useState, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import BasketProductItem, {roundToNearest} from '../../components/BasketProductItem/BasketProductItem';
import {useAuthSlice} from "../../../slices/useAuth";
import styles from "./BasketProduct.module.css";
import {axiosApi} from "../../../libs/axios";

const BasketSection = () => {
    const {
        clearProducts,
        clearCart,
        authState: {isAuth, cart, category, isLoading, id},
    } = useAuthSlice();
    const [comment, setComment] = useState<string>('');
    const [load, setLoad] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isAuth) navigate('/login');
    }, [isAuth, navigate]);

    // useEffect(() => {
    //     if (!cart.length) navigate('/');
    // }, [cart, navigate]);

    const totalPrice = cart.reduce((price, obj) => {
        return price + ((obj.id.prices.find(({crmId}) => crmId && crmId === category)?.value || 0) * obj.quantity);
    }, 0);

    const createOrder = async () => {
        try {
            if (cart.some(({quantity}) => !quantity || quantity === 0)) {
                return alert('Заповніть всі поля кількості або видаліть' +
                    ' непотрібні продукти')
            }
            const products = cart.map(i => {
                const price = i.id.prices.find(({crmId}) => crmId && crmId === category)?.value;
                const totalPrice = price && roundToNearest((price * +i.quantity));
                return {
                    product: i.id._id,
                    quantity: +i.quantity,
                    price,
                    totalPrice,
                };
            });
            const body = {
                totalPrice: roundToNearest(totalPrice),
                products,
                user: id,
                comment,
                crmId: '',
            };
            setLoad(true);
            const {data} = await axiosApi.post(`/api/orders/create`, body);
            console.log(data);
            if (data.status === 'success') {
                setLoad(false);
                clearCart();
                navigate('/profile/basket/success');
            }
        } catch
            (e) {
            setLoad(false);
            console.log(e);
        }
    };

    return (
        <div className={styles.basket__section}>
            <div className='container'>
                <Link to={'/'} className='home__link'>Головна</Link>

                <div className={styles.basket__wrapper}>
                    <div className={styles.basket__head}>
                        <p>Товари</p>
                        <p>Кількість</p>
                        <p>Усього</p>
                    </div>
                    <div className={styles.basket__list}>
                        {cart.length > 0 && cart.map(product => (
                            <BasketProductItem key={product._id} product={product}/>
                        ))}
                    </div>
                    <div className={styles.cart__bottom}>
                        <div className={styles.cart__description}>
                            <label htmlFor={'comment'}>Коментарі до замовлення</label>
                            <textarea
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                id={'comment'}
                                name={'comment'}
                            >
                </textarea>
                        </div>
                        <div className={styles.cart__result}>
                            <div className={styles.result__left}>
                                <button disabled={isLoading} onClick={() => clearProducts()}>Очистити корзину</button>
                                <button disabled={isLoading} onClick={() => navigate('/')}>Продовжити покупки</button>
                            </div>
                            <div className={styles.result__right}>
                                <div className={styles.cart__sum}>
                                    Сума:
                                    <p> {roundToNearest(totalPrice)} грн.</p>
                                </div>

                                <button
                                    className={styles.send__btn}
                                    disabled={load} onClick={createOrder}>
                                    {load ? 'Замовлення створюється' : 'Oформити замовлення'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default BasketSection;
