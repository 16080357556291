import { OrderType, ShipmentType } from '../../pages/public/OrdersPage';
import React, { useState } from 'react';
import OrderListProductItem from '../OrderListProductItem/OrderListProductItem';
import styles from '../OrderListProductItem/OrderProduct.module.css';

function formatToKievTime(dateString: string): string {
  const date = new Date(dateString);
  const formatterOptions = { timeZone: 'Europe/Kiev' };
  const yearFormatter = new Intl.DateTimeFormat('uk-UA', { ...formatterOptions, year: 'numeric' });
  const monthFormatter = new Intl.DateTimeFormat('uk-UA', { ...formatterOptions, month: '2-digit' });
  const dayFormatter = new Intl.DateTimeFormat('uk-UA', { ...formatterOptions, day: '2-digit' });
  const timeFormatter = new Intl.DateTimeFormat('uk-UA', {
    ...formatterOptions,
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });
  const year = yearFormatter.format(date);
  const month = monthFormatter.format(date);
  const day = dayFormatter.format(date);
  const time = timeFormatter.format(date);
  return `${day}.${month}.${year} ${time}`;
}

const OrderItem = ({ order, shipment }: { order: OrderType, shipment: ShipmentType | null }) => {
  const [open, setOpen] = useState<boolean>(false);
  const shipmentTotalPrice = shipment && shipment.products.reduce((acc, { totalPrice }) => {
    return acc + totalPrice;
  }, 0);

  console.log(order.products);
  return (<>
      <div className={styles.order__item}>
        <p className={styles.item__number}>{order.orderNumber}</p>
        <p className={styles.item__date}>{formatToKievTime(order.createdOn)}</p>
        <p className={styles.item__sum}>{order.totalPrice} грн.</p>
        <button
          className={styles.item__btn}
          onClick={() => setOpen(p => !p)}>{!open ? 'Розгорнути' : 'Згорнути'}
        </button>
      </div>
      {open &&
        <div className={styles.order__details}>
          <ul className={styles.details__list}>
            {order.products.map(product => {
              if (product && product.product && product.product._id) {
                return <OrderListProductItem key={product.product._id} product={product} />;
              } else return '';
            })}
          </ul>
          <ul>
            {shipment && <div><b>Відгрузка:</b></div>}
            {shipment && shipment?.products.map(product => <OrderListProductItem
              key={product.product._id} product={product} />)}
          </ul>
          {shipment && <p className={styles.order__total__info}>
            <b>Всього {shipment && shipment.products.length} шт. {shipmentTotalPrice && shipmentTotalPrice} грн.</b>
          </p>}
        </div>}
    </>
  );
};

export default OrderItem;
